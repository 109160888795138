.lobby-container {
display: flex;
flex-direction: column;
align-items: center;
margin-top: -50px;
width: 100%;
}

.room-code {
  margin-bottom: 20px;
  text-align: center;
}

.app-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.host-screen {
  margin-left: -20%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  width: 140%;
}

.players-container {
  margin: 0 20px;
  text-align: center;
  flex: 1;
  min-height: 60vh; /* Adjust the height as needed */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content to the top */
  align-items: center;
  border: 0.1px solid #ccc; 
  min-width: 30%;
}

.settings-trivia {
  border: 0.1px solid #ccc; 
  font-size: 2rem;
  min-width: 20%;
  margin: 50px;
}

.start-game {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Patrick Hand', 'Comic Sans MS', cursive, sans-serif;
  cursor: pointer;
  transition: background-color 0.9s ease, color 0.9s ease;
  z-index: 1000;
  background-color: #f0f0f0;
  color: #000;
  font-size: 2rem;
}

.start-game:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.game-mode-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-family: 'Patrick Hand', 'Comic Sans MS', cursive, sans-serif;
  cursor: pointer;
  transition: background-color 0.9s ease, color 0.9s ease;
  background: conic-gradient(from 0deg, red, white, red, white, red);
  background-size: 200% 200%;
  animation: swirl 4s infinite linear;
}

@keyframes swirl {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.categories-scroll-box {
  max-height: 45vh;
  max-width: 400px;
  overflow-y: auto;
  border: 0px solid #ccc;
  padding: 10px;
}

.player-item {
  cursor: pointer;
}

.player-item:hover {
  text-decoration: line-through;
}

.category-selection-container {
  padding: 1rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.category-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  overflow-y: auto;
  padding: 0.5rem;
  flex-grow: 1;
}

.category-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 0.5rem;
  min-height: 150px;
  transition: all 0.3s ease;
}

.category-image {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-bottom: 0.5rem;
}

.category-name {
  font-style: italic;
  text-align: center;
  font-weight: bold;
  color: white;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.6);
}

.leaderboard-content{
  margin:5%;
}