.game-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
}

.board-container {
  position: relative;
  width: 600px;
  height: 600px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
}

.board-square {
  position: absolute;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid black;
}

.start {
  background-color: #ffff00;
  border-radius: 15px;
  font-size: 16px;
}

.finish {
  background-color: #00ffff;
  border-radius: 15px;
  font-size: 16px;
}

.yellow { background-color: #ffffaa; }
.blue { background-color: #aaaaff; }
.green { background-color: #aaffaa; }
.red { background-color: #ffaaaa; }
.pick { background-color: #e6e6fa; }
.warp { 
  background-color: #800080;
  color: white;
}

.player-token {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid black;
  z-index: 10;
  transform: translate(-50%, -50%);
}

.game-info {
  text-align: center;
}

.options {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.option-button {
  padding: 5px 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.option-button:hover {
  background-color: #45a049;
}