@font-face {
  font-family: 'Alloy Ink';
  src: url('../font/AlloyInk-nRLyO.ttf') format('truetype'),
       url('../font/AlloyInk-lgdWw.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Quill Ink';
  src: url('../font/Ink.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.AnimatedTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background: none;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: auto;
  min-height: 60px;
}

.titleContainer {
  text-align: center;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
}

.animatedTitle {
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
  position: relative;
  font-family: 'Alloy Ink', 'Patrick Hand', 'Comic Sans MS', cursive, sans-serif;
  line-height: 1;
}

@media (min-width: 320px) and (max-width: 480px) {
  .animatedTitle { font-size: 2rem; }
}

@media (min-width: 481px) and (max-width: 768px) {
  .animatedTitle { font-size: 2.5rem; }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .animatedTitle { font-size: 3rem; }
}

@media (min-width: 1025px) {
  .animatedTitle { font-size: 3.5rem; }
}

body.party-mode::before,
body.party-mode::after {
  background-size: var(--background-size, initial);
  animation-duration: var(--animation-duration, initial);
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  pointer-events: none;
}

body.party-mode.light {
  animation: backgroundShiftLight 5s ease infinite;
}

body.party-mode.dark {
  animation: backgroundShiftDark 5s ease infinite;
}

body.party-mode.light::before,
body.party-mode.dark::before {
  animation-name: dotMoveLeft, radialPulsenew;
}

body.party-mode.light::after,
body.party-mode.dark::after {
  top: 15px;
  animation-name: dotMoveRight, radialPulsenew;
}

body.party-mode.light::before,
body.party-mode.light::after {
  animation-name: dotMoveLeft, radialPulsenew;
}

body.party-mode.dark::before,
body.party-mode.dark::after {
  animation-name: dotMoveLeft, radialPulsenewdark;
}

body.party-mode.light::after,
body.party-mode.dark::after {
  top: 15px;
  animation-name: dotMoveRight, radialPulsenew;
}

body.party-mode.dark::after {
  animation-name: dotMoveRight, radialPulsenewdark;
}

@keyframes radialPulsenew {
  0%, 100% { background-image: radial-gradient(circle, rgba(255,255,255,0.8) 10%, rgba(255,255,255,0) 60%); }
  50% { background-image: radial-gradient(circle, rgba(255,255,255,0.8) 10%, rgba(255,255,255,0) 60%); }
}

@keyframes radialPulsenewdark {
  0%, 100% { background-image: radial-gradient(circle, rgba(0,0,0,0.8) 10%, rgba(0,0,0,0) 60%); }
  50% { background-image: radial-gradient(circle, rgba(0,0,0,0.8) 10%, rgba(0,0,0,0) 60%); }
}