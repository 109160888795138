.showl {
    position: fixed;
    top: 10px;
    right: 10px;
    color: var(--toggle-color);
    transition: color 0.9s ease;
    text-align: left;
    width: 5rem;
    height: 5rem;
    font-size: 4rem;
  }