.spinner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  transition: transform 3s cubic-bezier(0.25, 0.1, 0.25, 1);
}

.spinner-section {
  position: absolute;
  width: 50%;
  height: 50%;
  transform-origin: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
}

.spinner-section:nth-child(1) { transform: rotate(0deg) skew(30deg); background-color: #ff6b6b; }
.spinner-section:nth-child(2) { transform: rotate(60deg) skew(30deg); background-color: #4ecdc4; }
.spinner-section:nth-child(3) { transform: rotate(120deg) skew(30deg); background-color: #45b7d1; }
.spinner-section:nth-child(4) { transform: rotate(180deg) skew(30deg); background-color: #f7d794; }
.spinner-section:nth-child(5) { transform: rotate(240deg) skew(30deg); background-color: #ff9ff3; }
.spinner-section:nth-child(6) { transform: rotate(300deg) skew(30deg); background-color: #54a0ff; }

.spinner-arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 20px solid black;
  transform: translate(-50%, -50%);
}

.spin-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
}

.spin-result {
  margin-top: 10px;
  font-size: 20px;
  font-weight: bold;
}

@media (max-width: 768px) {
  .spinner {
    width: 150px;
    height: 150px;
  }

  .spinner-section {
    font-size: 18px;
  }

  .spin-button {
    font-size: 16px;
  }
}

.spinners-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.player-spinner {
  width: 45%;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .player-spinner {
    width: 100%;
  }
}