button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Patrick Hand', 'Comic Sans MS', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.9s ease, color 0.9s ease;
    z-index: 1000;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
  }
  
  .overlay-content h1,
  .overlay-content h2 {
    margin: 10px 0;
  }
  
  .overlay-content button {
    margin: 10px;
    padding: 10px 20px;
    cursor: pointer;
  }
  