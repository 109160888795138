/* Lobby.css */
.lobby-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -50px;
    width: 100%;
  }
  
  .room-code {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .app-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }

  .host-screen {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100%;
  }
  
  .players-container {
    margin: 0 20px;
    text-align: center;
    flex: 1;
    min-height: 300px; /* Adjust the height as needed */
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Align content to the top */
    align-items: center;
    border: 0.1px solid #ccc; 
  }

  .settings {
    border: 0.1px solid #ccc; 
    font-size: 2rem;
  }
  
  .start-game {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Patrick Hand', 'Comic Sans MS', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.9s ease, color 0.9s ease;
    z-index: 1000;
    background-color: #f0f0f0;
    color: #000;
    font-size: 2rem;
  }
  
  .start-game:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .game-mode-button {
    padding: 10px 20px;
    margin: 10px;
    border: none;
    border-radius: 10px;
    font-size: 16px;
    font-family: 'Patrick Hand', 'Comic Sans MS', cursive, sans-serif;
    cursor: pointer;
    transition: background-color 0.9s ease, color 0.9s ease;
    background: conic-gradient(from 0deg, red, white, red, white, red);
    background-size: 200% 200%;
    animation: swirl 4s infinite linear;
  }
  
  @keyframes swirl {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 100% 100%;
    }
  }

