.host-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}


.title-bar {
background-color: rgba(255, 255, 255, 0.5);
display: flex;
justify-content: space-between;
align-items: center;
padding: 5px 10px;
position: fixed;
top: 0;
left: 0;
right: 0;
width: auto;
color: white;
z-index: 1000;
height: auto;
max-height: 80px;
overflow: hidden;
}

.title-bar.light {
background-color: rgba(0, 0, 0, 0.5);
}

.title-bar.dark {
background-color: rgba(255, 255, 255, 0.5);
}

.join-message {
text-align: center;
margin-left: 290px;
flex: 1;
}

.join-message h2 {
font-size: 2.5em;
margin: 0;
}

.red-text {
color: red;
}

.room-info {
text-align: right;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
}

.room-info h2,
.room-info h4 {
margin: 0;
padding: 2px 0;
font-size: 1em;
white-space: nowrap;
}

.room-info h4 {
font-size: 0.7em;
}

@media (max-width: 768px) {
.title-bar {
    flex-direction: column;
    align-items: stretch;
    padding: 5px;
}

.join-message,
.room-info {
    text-align: center;
}

.room-info {
    align-items: center;
}
}


.carousel-container {
position: relative;
width: 100%;
height: calc(100vh - 60px); /* Adjust based on your title bar height */
overflow: hidden;
}

.carousel-item {
position: absolute;
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
will-change: transform, opacity;
}

.speaker-screen,
.guessing-screen {
  width: 80%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  overflow: auto;
}


.leaderboard-container {
    position: absolute;
    top: 70px; /* Adjust based on your title bar height */
    right: 20px;
    width: 250px;
    padding: 1rem;
  }

  .screen-container {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  }
  
.guesses-container {
display: flex;
flex-direction: column;
}

.guess-block {
    border: 1px solid #000;
    padding: 10px;
    margin: 5px 0;
}
.voters {
    margin-top: 5px;
}
.correct {
    color: green;
}
.incorrect {
    color: red;
}