body::before,
body::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 30px 30px;
  animation-duration: 10s, 150s;
  animation-timing-function: linear, ease-in-out;
  animation-iteration-count: infinite, infinite;
  transition: background-color 0.9s ease;
}

body.light::before,
body.dark::before {
  animation-name: dotMoveLeft, radialPulse;
}

body.light::after,
body.dark::after {
  top: 15px;
  animation-name: dotMoveRight, radialPulse;
}

body.light::before,
body.light::after {
  animation-name: dotMoveLeft, radialPulse;
}

body.dark::before,
body.dark::after {
  animation-name: dotMoveLeft, radialPulseDark;
}

body.light::after,
body.dark::after {
  top: 15px;
  animation-name: dotMoveRight, radialPulse;
}

body.dark::after {
  animation-name: dotMoveRight, radialPulseDark;
}

@keyframes radialPulse {
  0%, 100% { background-image: radial-gradient(circle, rgba(255,255,255,0.8) 10%, rgba(255,255,255,0) 1%); }
  50% { background-image: radial-gradient(circle, rgba(255,255,255,0.8) 10%, rgba(255,255,255,0) 20%); }
}

@keyframes radialPulseDark {
  0%, 100% { background-image: radial-gradient(circle, rgba(0,0,0,0.8) 10%, rgba(0,0,0,0) 1%); }
  50% { background-image: radial-gradient(circle, rgba(0,0,0,0.8) 10%, rgba(0,0,0,0) 20%); }
}

@keyframes dotMoveLeft {
    from { background-position: 0 0; }
    to { background-position: 30px 0; }
  }
  
  @keyframes dotMoveRight {
    from { background-position: 0 0; }
    to { background-position: -30px 0; }
  }
  